<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="结算单号">
              <a-input v-model="queryParam.serial_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.order_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="接单日期">
              <a-range-picker @change="onDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <div class="table-operator">
              <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :scroll="{ x: 'max-content' }"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="printDebitSheet(record, index)">查看</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record, index)">编辑</a>
          <template v-if="record.fee_complete === 0">
            <a-divider type="vertical" />
            <a @click="handleAddFees(record, index)">加入费用</a>
          </template>
          <template v-if="record.audit_status === 4">
            <a-divider type="vertical" />
            <a @click="handleBillApply(record, index)">重新申请</a>
          </template>
        </template>
      </span>
      <span slot="inner_serial" slot-scope="text, record, index">{{ '#' + (index + 1 ) }}</span>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '待提交' }}
        </a-tag>
      </div>
      <div slot="invoiceFeeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <span v-if="item.pay_target_name">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
          <span v-else>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.invoices" :key="item.id">
          <!-- <span>发票号：{{ item.invoice_no }}</span> -->
          <span v-if="item.invoice_type_code==='026'||item.invoice_type_code==='028'||item.invoice_type_code==='01'||item.invoice_type_code==='02'">
            发票号：
            <a v-if="item.void_status === 0 && item.is_download === '1'" style="color: red" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
            <span v-if="item.void_status === 1">{{ item.invoice_no }}<a-tag color="red">失效</a-tag></span>
            <a v-if="item.void_status === 0 && item.is_download === '0'" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
          </span>
          <span v-else>发票号：{{ item.invoice_no }}</span>
        </div>
      </div>
      <div slot="fmsLogsItem" slot-scope="text, record">
        <div v-for="item in record.fms_capital_claim_logs" :key="item.id">
          <span>认领金额：{{ item.claim_amount }}认领人：{{ item.claim_ant }}</span>
        </div>
      </div>
    </s-table>
    <a-modal
      :visible="addFeesVisible"
      :confirmLoading="addFeesConfirmLoading"
      title="费用加入结算单"
      :width="720"
      @cancel="addFeesVisible = false"
      @ok="submitAddFees"
    >
      <a-form-item label="费用是否完整">
        <a-select
          v-model="feeComplete"
          style="width: 50%"
        >
          <a-select-option value="complete" key="complete">是</a-select-option>
          <a-select-option value="incomplete" key="incomplete">否</a-select-option>
        </a-select>
      </a-form-item>
      <a-checkbox
        :indeterminate="addFeesIndeterminate"
        :checked="addFeesCheckAll"
        @change="onCheckAllChangeAddFees"
        style="padding-left:10px;padding-bottom:10px;"
      >
        全选
      </a-checkbox>
      <a-checkbox-group v-model="checkedAddFeesList" @change="onChangeAddFees" style="width:100%;">
        <div style="margin-bottom: 20px;" v-for="item in addFeesData" :key="item.id">
          <p style="margin: 10px 0;font-size: 16px;font-weight:bold">订单编号：{{ item.serial_num }}</p>
          <div class="fee-area">
            <div class="fee-list">
              <p style="margin: 5px auto;">应付费用</p>
              <div v-for="c in item.unbalance_fees" :key="c.id">
                <a-checkbox v-if="c.fee_type===2" :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>
    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceConfirmLoading"
      title="开票申请"
      :width="980"
      @cancel="applyInvoiceCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="20">
          <div>
            <a-divider orientation="left">订单信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位">
                <a-input v-model="bizAuditForm.customer" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="所属部门">
                <a-input v-model="bizAuditForm.department" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="录入人">
                <a-input v-model="bizAuditForm.creator" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="申请时间">
                <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-divider orientation="left"
          >费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
          </a-divider>
          <a-col :span="24">
            <a-row v-for="item in bizAuditForm.fees" :key="item.id">
              <a-alert
                :message="`业务编号:${item.orderNums}`"
                type="info"
                show-icon
                style="margin-bottom:10px;"
              />
              <a-col
                :span="8"
                v-for="fee in item.feeList"
                :key="fee.id"
                style="padding-left:15px;padding-bottom:10px;"
              >
                <span>{{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</span>
              </a-col>
            </a-row>
          </a-col>
          <div>
            <a-col :span="24">
              <a-form-model-item label="申请备注">
                <a-textarea v-model="applyInvoiceForm.apply_remark"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">开票信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位/客户名称" prop="customer_name">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo2"
                  :filter-option="false"
                  :not-found-content="fetching2 ? undefined : null"
                  @search="getDepartmentCustomer2"
                  @change="changeCustomerInfo2"
                >
                  <template v-if="fetching2" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerInfoOps2" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="纳税人识别号" prop="tax_no">
                <a-input v-model="applyInvoiceForm.tax_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="地址">
                <a-input v-model="applyInvoiceForm.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="电话">
                <a-input v-model="applyInvoiceForm.mobile" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-select v-model="applyInvoiceForm.bank_card_no" @change="bankAccountChange">
                  <a-select-option
                    :value="item.account"
                    v-for="item in bankAccountOps"
                    :key="item.account">
                    {{ item.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户银行">
                <a-input v-model="applyInvoiceForm.bank" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="发票类型" prop="invoice_type">
                <a-select v-model="applyInvoiceForm.invoice_type">
                  <a-select-option :value="item.value" v-for="item in invoiceTypeList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税率" prop="tax_rate">
                <a-select v-model="applyInvoiceForm.tax_rate">
                  <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="费用是否完整" prop="fee_complete">
                <a-select v-model="applyInvoiceForm.fee_complete" >
                  <a-select-option value="complete" key="complete">是</a-select-option>
                  <a-select-option value="incomplete" key="incomplete">否</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <edit-table
                :columns="invoiceSubjectColumns"
                :sourceData="invoiceSubjectData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getInvoiceSubjectTableDate"></edit-table>
            </a-col>
            <a-col :span="24">
              <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="开票备注">
                <a-input v-model="applyInvoiceForm.invoice_remark"></a-input>
              </a-form-model-item>
            </a-col>
          </div>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="printCumsBillModel"
      :confirmLoading="printCumsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印客服结算单"
      @cancel="printCumsBillModel = false"
    >
      <div id="printCumsBill">
        <cums-bill
          :currentData="currentData"
          :balanceData="balanceBillData"
        ></cums-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printCumsBillBtn"
                  v-print="printCumsBill">确认打印</a-button>
        <a-button type="primary" @click="printCumsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="blanceFeeVisible"
      :confirmLoading="blanceFeeLoading"
      title="编辑结算单"
      :width="1200"
      @cancel="blanceFeeCancel"
      @ok="blanceFeeSubmit"
    >
      <!--      已经保存到结算单的费用-->
      <a-card title="结算单费用" style="margin-bottom: 10px">
        <div class="balanceTitle">订单编号:{{ balanceRecord.order_nums }}</div>
        <a-row>
          <a-col :span="24">
            <div class="balanceTitle">应付费用</div>
            <div v-for="(item, index) in balanceRecord.pay_fees" :key="item.id">{{ item.fee_name }}/{{ item.pay_target_name }}/{{ item.money }}/{{ item.currency_name }}<a-button type="link" @click="delBalanceFee(index,'pay')">删除</a-button></div>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="订单费用">
        <a-checkbox-group @change="balanceFeeChange" v-model="balanceFeeIds" style="width: 100%">
          <a-row v-for="item in balanceData" :key="item.id">
            <div class="balanceTitle">订单编号:{{ item.order_nums }}</div>
            <a-col :span="24">
              <div class="balanceTitle">应付费用</div>
              <a-checkbox v-for="fee in item.pay_fees" :key="fee.id" :value="fee.id">
                {{ fee.fee_name }}/{{ fee.pay_target_name }}/{{ fee.money }}/{{ fee.currency_name }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-card>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import { CumsFinanceFunc, getCumsBalanceFunc, getCumsBalanceFeeFunc, queryUnbalanceFees, addFeesToBalance } from '@/api/cums'
import { filterOption } from '@/utils/util'
import { getCustomer, getCustomerInfo } from '@/api/common'
import moment from 'moment';
import { getBizAuditPage, getchargeTax, getEInvoiceAndSendEmail, listSecondInvoiceSubject } from '@/api/fms'
import itmsBill from '@/views/fms/bill/FmsItmsBill';
import debounce from 'lodash/debounce'
import cumsBill from '@/views/fms/bill/CumsBill';
export default {
  name: 'CumsBalanceBill',
  components: {
    STable,
    itmsBill,
    cumsBill,
    EditTable
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    this.lastFetchId2 = 0;
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800);
    return {
      balanceData: [],
      checkedBalanceList: [],
      balanceRecord: {},
      blanceFeeVisible: false,
      blanceFeeLoading: false,
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          fixed: 'left',
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'order_nums',
          fixed: 'left',
          width: 200,
          customRender: (text) => {
            return text.join('\n')
          }
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name',
          fixed: 'left',
          width: 150
        },
        {
          title: '结算单号',
          dataIndex: 'balance_num'
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          width: 100,
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: '接单日期',
          dataIndex: 'receiving_order_dates',
          width: 110,
          customRender: (text) => {
            if (typeof text !== 'undefined') {
              const names = text.map(i => {
                return moment(i).format('YYYY/MM/DD')
              })
              return names.join('\n')
            } else {
              return ''
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency_name',
          width: 100
        },
        {
          title: '税率',
          dataIndex: 'tax_rate',
          width: 80
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 150
        },
        {
          title: '开票费用',
          dataIndex: 'fees',
          scopedSlots: { customRender: 'invoiceFeeItem' }
        },
        {
          title: '发票信息',
          dataIndex: 'invoices',
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '认领信息',
          dataIndex: 'fms_capital_claim_logs',
          width: 150,
          scopedSlots: { customRender: 'fmsLogsItem' }
        },
        {
          title: '创建人',
          width: 100,
          dataIndex: 'create_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getCumsBalanceFunc('get_cums_balance_page', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      businessAuditFormVisible: false,
      businessAuditConfirmLoading: false,
      defaultDate: [],
      selectedRowKeys: [],
      taxRateOps: [],
      applyInvoiceVisible: false,
      applyInvoiceConfirmLoading: false,
      indeterminate: false,
      checkAll: true,
      totalAmount: '',
      selectedRows: [],
      checkedList: [],
      feeOrderList: [],
      bankAccountOps: [],
      invoiceRegOps: [],
      feeComplete: null,
      addFeesVisible: false,
      addFeesConfirmLoading: false,
      // addFeesType: 1,
      addFeesData: [],
      checkedAddFeesList: [],
      addFeesBalanceId: null,
      addFeesIndeterminate: false,
      addFeesCheckAll: true,
      // 商务审核申请form
      form: {
        pay_method: '',
        appendix_num: null,
        apply_remark: '',
        invoice_num: []
      },
      // 开票申请form
      applyInvoiceForm: {
        apply_remark: '',
        customer_name: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: ''
      },
      bizAuditForm: {
        audit_record_id: '',
        customer: '',
        department: '客服部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      invoiceTypeList: [
        {
          value: 1, name: '专用发票'
        },
        {
          value: 2, name: '普通发票'
        },
        {
          value: 3, name: '免税发票'
        }
      ],
      applyInvoiceRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        invoice_type: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }],
        fee_complete: [{ required: true, message: '请确认费用是否完整', trigger: 'change' }]
      },
      rules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }],
        pay_method: [{ required: true, message: '请选择付款方式', trigger: 'change' }]
      },
      balanceFeeIds: [],
      printCumsBillModel: false,
      printCumsBillModelLoading: false,
      printCumsBill: {
        id: 'printCumsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentData: {},
      balanceBillData: [],
      balance_id: '',
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 5, name: `待开票` },
        { value: 6, name: `已开票` }
      ],
      feeStatusMaps: {
        1: { value: 1, name: '待审核', color: '#faad14' },
        2: { value: 2, name: '业务已审核', color: '#1890ff' },
        3: { value: 3, name: '商务待审核', color: '#4cd964' },
        4: { value: 4, name: '商务审核被驳回', color: '#ff4d4f' },
        5: { value: 5, name: '待开票', color: '#ffe8e9' },
        6: { value: 6, name: '已开票', color: '#f8fadf' },
        9: { value: 9, name: '商务已审核(待付款)', color: '#e7ffec' },
        13: { value: 13, name: '已付款', color: '#e7ffec' }
      },
      balanceStatusMaps: {
        1: { value: 1, name: '未完成', color: '#faad14' },
        2: { value: 2, name: '已完成', color: '#4cd964' }
      },
      customerInfo: undefined,
      fetching: false,
      customerOps: [],
      audit_id: null,
      customerInfo2: undefined,
      fetching2: false,
      customerInfoOps2: [],
      totalInvoiceAmount: 0.00,
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      secondInvoiceSubjectList: [],
      invoiceSubjectData: [],
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        }
      ]
    }
  },
  watch: {
    printCumsBillModel: function (newvalue, value) {
      if (newvalue) {
      this.$nextTick(function () {
        const a4height = 900 // a4高度
        let pageHeight = 160// 第一页初始高度
        const controls = document.querySelectorAll('.tbody')
        for (let i = 0; i < controls.length; i++) {
          const height = controls[i].clientHeight
          pageHeight += (height)
          if (pageHeight > a4height) {
            const html = controls[i].outerHTML
            controls[i].outerHTML = `<tr class='page_wrapper'><td style='height: ${a4height - (pageHeight - height) + 50}px' colspan='20'></td></tr>` + html
            pageHeight = height + 50 // 减去表头+其他高度
          }
        }
      })
      } else {
        document.querySelectorAll('.page_wrapper').forEach(function (ctrl, index) {
          ctrl.remove()
        })
      }
    }

  },
  methods: {
    filterOption,
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.customerInfoOps2 = [];
      this.fetching2 = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.customerInfoOps2 = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value;
      this.applyInvoiceForm.customer_name = value !== undefined ? value.label : null;
      this.customerInfoOps2.forEach(item => {
        if (item.value === value.key) {
          this.applyInvoiceForm.customer_id = value.key
          this.applyInvoiceForm.tax_no = item.taxNum;
          this.applyInvoiceForm.address = item.street;
          this.applyInvoiceForm.mobile = item.phone;
          this.applyInvoiceForm.bank_card_no = item.account;
          this.applyInvoiceForm.bank = item.bankName;
        }
      });
      this.customerInfoOps2 = [];
      this.fetching2 = false;
    },
    // 结算单
    printDebitSheet(record) {
      if (typeof record.audit_id !== 'undefined') {
        getBizAuditPage('list_balance', { id: record.audit_id }).then(res => {
          // 统计数据
          let charge = 0
          let pay = 0
          let profit = 0
          res.balance_item_list.forEach(v => {
            v.charge_fees &&
            v.charge_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                charge += c.amount
              } else {
                charge += c.exchange_amount
              }
            })
            v.pay_fees &&
            v.pay_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                pay += c.amount
              } else {
                pay += c.exchange_amount
              }
            })
            profit += v.profit
          })
          this.balanceStatic = {
            charge: '应收总计:' + charge.toFixed(2),
            pay: '应付总计:' + pay.toFixed(2),
            profit: '利润总计:' + profit.toFixed(2)
          }
          this.balanceBillData = []
          this.balanceBillData = res.balance_item_list
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.currentData = {}
          this.currentData.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceStatic = this.balanceStatic
          this.currentData.currency = res.currency
          this.currentData.customer = record.customer_name
          this.currentData.record_no = record.balance_num
          this.currentData.applicant_name = record.create_name
          this.currentData.apply_remark = record.remark
          this.currentData.last_add_fees = res.last_add_fees
        }).finally(() => {
          this.printCumsBillModel = true
        })
      } else {
        this.$message.error('当前订单尚未提交开票申请~')
      }
    },
    balanceFeeChange(value) {
      this.balanceFeeIds = value
    },
    delBalanceFee(index, type) {
      if (type === 'charge') {
        this.balanceRecord.charge_fees.splice(index, 1)
      } else {
        this.balanceRecord.pay_fees.splice(index, 1)
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam.start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam.start_date = null
        this.queryParam.end_date = null
      }
    },
    // 删除结算单
    handleDelete(type, record) {
      this.$confirm({
        title: `确认提示`,
        content: `确定要删除？`,
        onOk: () => {
          getCumsBalanceFunc('delete_cums_balance', { 'id': record.id }).then(res => {
            this.$notification['success']({
              message: `提示`,
              description: `删除成功`
            });
          }).finally(() => {
            this.$refs.table.refresh(false);
          });
        }
      });
    },
    blanceFeeCancel() {
      this.balanceFeeIds = []
      this.blanceFeeVisible = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record)
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        for (const item of changeRows) {
          this.selectedRows.push(item)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          for (const item of changeRows) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    handleBillApply(record) {
      this.checkedList = []
      const recordRow = [record]
      this.totalAmount = ''
      const ids = recordRow.map(r => {
        return r.customer_name
      })
      const uniqueIds = Array.from(new Set(ids))
      if (uniqueIds.length > 1) {
        this.$message.warning('请选择同一客户信息')
      } else {
        let flag = false
        const feeMaps = []
        const map = new Map()
        recordRow.forEach(row => {
          const orderFee = {
            orderNums: row.order_nums.join(','),
            feeList:
              row.charge_fees &&
              row.charge_fees
                .filter(e => e.fee_status === 2 || e.fee_status === 4)
                .map(e => {
                  if (map.has(e.currency_name)) {
                    map.set(e.currency_name, e.money + map.get(e.currency_name))
                  } else {
                    map.set(e.currency_name, e.money)
                  }
                  if (e.fee_status === 2 || e.fee_status === 4) {
                    flag = true
                  }
                  this.checkedList.push(e.id)
                  return {
                    id: e.id,
                    feeName: e.fee_name,
                    money: e.money,
                    currency_name: e.currency_name
                  }
                })
          }
          // const payFeeList = row.pay_fees.filter(e => e.fee_status === 2 || e.fee_status === 4)
          const payFeeList = row.pay_fees.map(e => {
                  if (map.has(e.currency_name)) {
                    map.set(e.currency_name, e.money + map.get(e.currency_name))
                  } else {
                    map.set(e.currency_name, e.money)
                  }
                  if (e.fee_status === 2 || e.fee_status === 4) {
                    flag = true
                  }
                  this.checkedList.push(e.id)
                  return {
                    id: e.id,
                    feeName: e.fee_name,
                    money: e.money,
                    currency_name: e.currency_name
                  }
                })
                debugger
          payFeeList.forEach(e => orderFee.feeList.push(e))
          feeMaps.push(orderFee)
        })
        if (flag) {
          this.bankAccountOps = []
          this.getCustomerOps(recordRow[0].customer_name, recordRow[0].customer_id)
          this.bizAuditForm = {
            ...this.bizAuditForm,
            customer: recordRow[0].customer_name,
            fees: feeMaps,
            creator: recordRow[0].create_name
          }
          this.balance_id = recordRow[0].id
          this.audit_id = recordRow[0].audit_id
          for (const key of map.keys()) {
            this.totalAmount += map.get(key).toFixed(2) + key + ','
          }
          this.applyInvoiceForm.apply_remark = record.invoice_remark_info
          this.applyInvoiceVisible = true
        } else {
          this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
        }
      }
    },
    // 提交开票
    handleApplyFormSubmit() {
      this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          this.applyInvoiceForm.invoice_subject_data = this.invoiceSubjectData
          const data = {
            ...this.applyInvoiceForm,
            id_list: this.checkedList,
            balance_id: this.balance_id,
            audit_id: this.audit_id
          };
          CumsFinanceFunc('apply_ticket', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.totalAmount = '';
            this.applyInvoiceVisible = false;
            this.$refs.table.refresh(false);
          });
        }
      })
    },
    applyInvoiceCancel() {
      this.applyInvoiceVisible = false;
      this.totalAmount = '';
    },
    billOrverifyCancel() {
      this.businessAuditFormVisible = false;
      this.totalAmount = ''; // 每次关闭弹出框后将会将总金额进行重置
    },
    getCustomerOps(name, id) {
      getCustomerInfo({
        department: null,
        customerName: name,
        customerId: id
      }).then(res => {
        res.forEach(data => {
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.applyInvoiceForm.customer_id = typeof data.value !== 'undefined' ? data.value : ''
          this.applyInvoiceForm.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.applyInvoiceForm.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.applyInvoiceForm.address = typeof data.street !== 'undefined' ? data.street : ''
          this.applyInvoiceForm.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.applyInvoiceForm.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.applyInvoiceForm.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.applyInvoiceForm.bank = item.bank_name
        }
      })
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      const mapItem = new Map()
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      const mapList = new Map()
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    // 获得委托客户
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    // 编辑结算单
    handleEdit(record) {
      this.balanceRecord = JSON.parse(JSON.stringify(record))
      this.balanceFeeIds = []
      getCumsBalanceFeeFunc('get_cums_fees_by_balance_id', { 'balanceId': record.id }).then(res => {
        this.balanceData = res
      }).finally(() => {
        this.balanceRecord.order_nums = this.balanceRecord.order_nums.join(',')
        this.blanceFeeVisible = true
      })
    },
    handleAddFees(record) {
      this.feeComplete = null
      this.addFeesBalanceId = null
      queryUnbalanceFees({ 'id': record.id }).then(res => {
        this.addFeesData = res
        const pay = this.addFeesData
        .map(item => item.unbalance_fees)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.checkedAddFeesList = this.checkedAddFeesList.concat(payIdList)
      this.addFeesBalanceId = record.id
    }).finally(() => {
        this.addFeesVisible = true
    })
    },
    onChangeAddFees() {
      const pay = this.addFeesData
        .map(item => item.unbalance_fees)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.addFeesIndeterminate =
        !!this.checkedAddFeesList.length && this.checkedAddFeesList.length < payIdList.length
      this.addFeesCheckAll = this.checkedAddFeesList.length === payIdList.length
    },
    onCheckAllChangeAddFees(e) {
      this.addFeesCheckAll = e.target.checked
      this.addFeesIndeterminate = false
      this.checkedAddFeesList = []
      const pay = this.addFeesData
        .map(item => item.unbalance_fees)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      if (e.target.checked) {
        this.checkedAddFeesList = this.checkedAddFeesList.concat(payIdList)
      }
    },
    submitAddFees() {
      this.addFeesConfirmLoading = true
      addFeesToBalance({
      'balance_id': this.addFeesBalanceId,
      'fee_ids': this.checkedAddFeesList,
      'fee_complete': this.feeComplete })
      .then(res => {
        if (res) {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
        }
      }).finally(() => {
        this.addFeesVisible = false
        this.addFeesConfirmLoading = false
    })
    },
    // 结算单编辑
    blanceFeeSubmit() {
      const feeIds = []
      this.balanceRecord.pay_fees.forEach(data => {
        feeIds.push(data.id)
      })
      const data = {
        'fee_ids': feeIds,
        'id': this.balanceRecord.id,
        'balance_fee_ids': this.balanceFeeIds
      }
      getCumsBalanceFunc('update_cums_balance', data).then(res => {
        this.$message.success('编辑结算单成功')
        this.balanceFeeIds = []
        this.$refs.table.refresh(false);
      }).finally(() => {
        this.blanceFeeVisible = false
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam['customer_id'] = null
        this.customerOps = []
        this.fetching = false
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    balanceStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#4cd964'
      }
    },
    downloadInvoiceFile(item) {
      getEInvoiceAndSendEmail({ 'invoice_no': item.invoice_no, 'is_business': '1' }).then(v => {
        item.is_download = '1';
        if (v) {
          window.open(v, true);
        }
      })
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    listSecondInvoiceSubject({ department: 7 }).then(v => {
      this.secondInvoiceSubjectList = v
    })
  }
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
